// function to check Authentication for each route
// for any created route, put 'beforeEnter' function.

// Impory axios for requests to the microservices
// import axios from 'axios';

// /**
//  * @param {Date} date
//  */
// const datePart = (date) => date.toISOString().substring(0, 10);

// const uploadStatisticsToDB = async (data) => {
//   const url = `${window.node_base_url}general-info/add-analysis`;
//   const res = axios
//     .post(url, data)
//     .then((response) => response).catch((err) => {
//       console.error(err);
//       return err;
//     });

//   return res;
// };

// const recordTracking = async (to) => {
//   try {
//     switch (to.meta.name) {
//       case 'track_view_summary_body':
//         if (!window.localStorage.getItem(to.meta.name)) {
//           const data = {
//             count: 1,
//             created_at: new Date().toISOString().split('T')[0],
//             title: 'Summary Board',
//             track_type: 'page_view',
//             route: `${to.path}`,
//             user_id: parseInt(localStorage.getItem('user_id'), 10),
//           };
//           window.localStorage.setItem(to.meta.name, JSON.stringify(data));
//         } else {
//           const pageview = JSON.parse(window.localStorage.getItem(to.meta.name));

//           if (
//             datePart(new Date(pageview.created_at)) === datePart(new Date())
//           ) {
//             pageview.count += 1;
//             window.localStorage.setItem(to.meta.name, JSON.stringify(pageview));
//           } else {
//             await uploadStatisticsToDB(pageview).then((e) => {
//               if (e.status === 200) {
//                 const data = {
//                   count: 1,
//                   created_at: new Date().toISOString().split('T')[0],
//                   title: 'Summary Board',
//                   track_type: 'page_view',
//                   route: `${to.path}`,
//                   user_id: parseInt(localStorage.getItem('user_id'), 10),
//                 };
//                 window.localStorage.setItem(to.meta.name, JSON.stringify(data));
//               }
//             });
//           }
//         }
//         break;
//       case 'track_view_customer_list':
//         if (!window.localStorage.getItem(to.meta.name)) {
//           const data = {
//             count: 1,
//             created_at: new Date().toISOString().split('T')[0],
//             title: 'Customer List',
//             track_type: 'page_view',
//             route: `${to.path}`,
//             user_id: parseInt(localStorage.getItem('user_id'), 10),
//           };
//           window.localStorage.setItem(to.meta.name, JSON.stringify(data));
//         } else {
//           const pageview = JSON.parse(window.localStorage.getItem(to.meta.name));

//           if (datePart(new Date(pageview.created_at)) === datePart(new Date())) {
//             pageview.count += 1;
//             window.localStorage.setItem(to.meta.name, JSON.stringify(pageview));
//           } else {
//             await uploadStatisticsToDB(pageview).then((e) => {
//               if (e.status === 200) {
//                 const data = {
//                   count: 1,
//                   created_at: new Date().toISOString().split('T')[0],
//                   title: 'Customer List',
//                   track_type: 'page_view',
//                   route: `${to.path}`,
//                   user_id: parseInt(localStorage.getItem('user_id'), 10),
//                 };
//                 window.localStorage.setItem(to.meta.name, JSON.stringify(data));
//               }
//             });
//           }
//         }
//         break;
//       default:
//         break;
//     }
//   } catch (err) {
//     console.error(err);
//   }
// };

// @ts-ignore
const checkAuth = async function checkAuth(to, from, next) {
    if (!window.localStorage.getItem('token')) {
        next({ name: 'login', query: { redirect: to.path } })
    } else {
        const modules = JSON.parse(window.localStorage.getItem('New_permissions'));
        const name = to.meta.name != null ? to.meta.name : null;
        const result = await modules.map((mod) => mod.permission.toLowerCase().includes(name));
        if (result.includes(true)) {
            next();
        } else {
            window.location.href = '/homepage/customers';
        }


        // next()
    }
};

/*
this function helps on recording the page loading numbers.
this function records on localStorage. The number are synced to database
at a specified time.
The function has been set to record information everyday.
If the date are same the function will treat
data as of the same day
*/

export default {
    routeslist: [

        // -------------------- TRANSACTIONS URL-----------------------------
        {
            path: '/homepage',
            component: () => import('./components/navbars/Horizontal-Nav-Bar.vue'),
            name: 'homepage',
            children: [
                // -------------------- CUSTOMER URL-----------------------------

                {
                    path: 'new-customer',
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/customers/CreateNewCustomer.vue'),
                    name: 'createcustomer',
                    meta: { name: 'createcustomer' },
                    beforeEnter: checkAuth,
                },

                {
                    path: 'customers',
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/customers/CustomerList.vue'),
                    name: 'customerlist:',
                    meta: { name: 'customerlist:' },
                    beforeEnter: checkAuth,
                    props: true,
                },
                {
                    path: 'customer-info/:customer_id',
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/customers/CustomerInfo.vue'),
                    name: 'customerlist1',
                    meta: { name: 'customerlist' },
                    beforeEnter: checkAuth,

                },
                {
                    path: 'add-ac',
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/ac-management/AddNewAC.vue'),
                    name: 'customerlist2',
                    meta: { name: 'customerlist' },
                    beforeEnter: checkAuth,
                },
                {
                    path: 'customer-analysis',
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/customers/CustomerAnalysis.vue'),
                    name: 'customeranalysis',
                    meta: { name: 'customeranalysis' },
                    beforeEnter: checkAuth,
                },
                // {
                //   path: 'analysis-page',
                //   component: () =>
                //     // @ts-ignore
                //     import('./components/administrator/analytics/KPIAnalysis.vue'),
                //   name: 'analysis',
                //   meta: { name: '' },
                //   beforeEnter: checkAuth,
                // },

                // {
                //   path: 'deduction-schedules',
                //   component: () =>
                //     // @ts-ignore
                //     import('./components/administrator/deduction-settings/DeductionHistory.vue'),
                //   name: 'deductionsettings',
                //   meta: { name: '' },
                //   beforeEnter: checkAuth,
                // },
                {
                    path: 'ac-asset',
                    component: () =>
                        import('./components/administrator/ac-management/AcAssets.vue'),
                    name: 'acassetlist',
                    meta: { name: 'acassetlist' },
                    // beforeEnter: checkAuth,
                },
                {
                    path: 'dashboard',
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/Dashboard.vue'),
                    name: 'summaryboard',
                    meta: { name: 'summaryboard' },
                    beforeEnter: checkAuth,
                },

                // {
                //   path: 'upload',
                //   component: () =>
                //     // @ts-ignore
                //     import('./components/administrator/deduction-settings/uploads/UploadToAWS.vue'),
                //   name: 'transaction 2',
                //   meta: { name: '' },
                //   beforeEnter: checkAuth,
                // },

                {
                    path: 'create-deduction/:customer_id/:customer_name',
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/customers/customer-components/CreateCustomerDeduction.vue'),
                    name: 'transaction 3',
                    meta: { name: 'transaction 3' },
                    beforeEnter: checkAuth,
                },
                {
                    path: "transaction-history-backup/:from_date/:to_date",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/BackupTransactionHistory.vue"
                        ),
                    name: "backuphistory",
                    meta: { name: "backuphistory" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "transaction-history-backup",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/BackupTransactionHistory.vue"
                        ),
                    name: "backuphistory1",
                    meta: { name: 'backuphistory' },
                    beforeEnter: checkAuth,
                },
                {
                    path: "transaction-history-live",
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/transactions/LiveTransactionHistory.vue'),
                    name: "livehistory",
                    meta: { name: 'livehistory' },
                    beforeEnter: checkAuth,
                },
                {
                    path: "transaction-history-live/:from_date/:to_date",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/LiveTransactionHistory.vue"
                        ),
                    name: "livehistory1",
                    meta: { name: "livehistory" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "subscription-history",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/SubscriptionHistory.vue"
                        ),
                    name: "subscription",
                    meta: { name: "subscription" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "instalment-history",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/InstalmentHistory.vue"
                        ),
                    name: "instalment:payment",
                    meta: { name: "instalment:payment" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "instalment-history/:from_date/:to_date",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/InstalmentHistory.vue"
                        ),
                    name: "instalment:payment1",
                    meta: { name: "instalment:payment" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "subscription-history/:from_date/:to_date",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/SubscriptionHistory.vue"
                        ),
                    name: "subscription1",
                    meta: { name: "subscription" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "transaction-summary",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/TransactionSummary.vue"
                        ),
                    name: "transactions:summary",
                    meta: { name: "transactions:summary" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "transaction-summary/:transaction_type/:title/:from_date/:to_date",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/transaction-summary-filters/TransactionSummaryViewComponent.vue"
                        ),
                    name: "transactions:summary1",
                    meta: { name: "transactions:summary" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "transaction-summary/quatery",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/transaction-summary-filters/TransactionSummaryThreeMonths.vue"
                        ),
                    name: "transaction 12",
                    meta: { name: "transaction 12" },
                    beforeEnter: checkAuth,
                },
                // {
                //   path: 'subscription-tracking',
                //   component: () =>
                //     // @ts-ignore
                //     import('./components/administrator/tracking/SubscriptionTracking.vue'),
                //   name: 'basicfee',
                //   meta: { name: '' },
                //   beforeEnter: checkAuth,
                // },
                {
                    path: "subscription-tracking",
                    component: () =>
                        import(
                            "./components/administrator/tracking/SubscriptionTrackingNew.vue"
                        ),
                    name: "basicfee",
                    meta: { name: "basicfee" },
                    beforeEnter: checkAuth,
                },

                {
                    path: "recharge-tracking",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/tracking/RechargeTracking.vue"
                        ),
                    name: "rechargefee",
                    meta: { name: "rechargefee" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "security-deposit",
                    name: "securitydeposit",
                    component: () => import('./components/administrator/security-deposits/SecurityDeposits.vue'),
                    meta: { name: "" },
                    // beforeEnter: ,
                },
                {
                    path: "deduction-history",
                    name: "deductionhistory",
                    component: () => import('./components/administrator/security-deposits/DeductionHistory.vue'),
                    meta: { name: "" },
                    // beforeEnter: ,
                }, 
                {
                    path: "penalties",
                    name: "penalties",
                    component: () => import('./components/administrator/security-deposits/PenaltyList.vue'),
                    meta: { name: "" },
                    // beforeEnter: ,
                },
                // {
                //   path: 'activation-codes',
                //   component: () =>
                //     // @ts-ignore
                //     import('./components/administrator/transactions/ActivationCodeList.vue'),
                //   name: 'activationcodelist',
                //   meta: { name: '' },
                //   beforeEnter: checkAuth,
                // },
                // {
                //   path: 'sent-sms',
                //   component: () =>
                //     // @ts-ignore
                //     import('./components/administrator/transactions/SMSList.vue'),
                //   name: 'sentsms',
                //   meta: { name: '' },
                //   beforeEnter: checkAuth,
                // },
                {
                    path: "create-manual-point",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/CreateManualPtApplication.vue"
                        ),
                    name: "manualptrequest",
                    meta: { name: "manualptrequest" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "manual-pt-approval",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/ManualPTApproval.vue"
                        ),
                    name: "manualptapproval1",
                    meta: { name: "manualptapproval" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "manual-pt-approval/:filter_id/:type",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/transactions/ManualPTApproval.vue"
                        ),
                    name: "manualptapproval",
                    meta: { name: "manualptapproval" },
                    beforeEnter: checkAuth,
                },
                // -------------------- ADMIN  SETTINGS-----------------------------

                {
                    path: "permission-groups",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/roles-permissions/GroupList.vue"
                        ),
                    name: "groupsofpermission",
                    meta: { name: "groupsofpermission" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "user-list",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/roles-permissions/UserList.vue"
                        ),
                    name: "userlist1",
                    meta: { name: "userlist" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "permission-settings",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/roles-permissions/PermissionSettings.vue"
                        ),
                    name: "permissionsettings",
                    meta: { name: "permissionsettings" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "reason-manual-point",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/roles-permissions/ReasonOfManualApproval.vue"
                        ),
                    name: "setupmanualptrequest",
                    meta: { name: "setupmanualptrequest" },
                    beforeEnter: checkAuth,
                },

                {
                    path: "user-permissions/:user_id/:fullname",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/roles-permissions/RoulesAndPermission.vue"
                        ),
                    name: "userlist",
                    meta: { name: "userlist" },
                    beforeEnter: checkAuth,
                },

                // -------------------- INVENTORY-----------------------------

                {
                    path: "inventory/summary",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/inventory/Summary.vue"
                        ),
                    name: "inventory 1",
                    meta: { name: "inventory 1" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "inventory/ac-asset",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/inventory/ac_asset/AssetList.vue"
                        ),
                    name: "inventory 2",
                    meta: { name: "inventory 2" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "inventory/bulk-asset",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/inventory/bulk_assets/BulkAssetList.vue"
                        ),
                    name: "inventory 3",
                    meta: { name: "inventory 3" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "inventory/ac-asset/details/:ac_asset_id",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/inventory/ac_asset/AssetInfo.vue"
                        ),
                    name: "inventory 4",
                    meta: { name: "inventory 4" },
                    beforeEnter: checkAuth,
                },
                // ALL ARRIVALS
                {
                    path: "inventory/all-arrivals",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/inventory/all_arrivals/AllArrivalList.vue"
                        ),
                    name: "inventory 5",
                    meta: { name: "inventory 5" },
                    beforeEnter: checkAuth,
                },
                // DISPATCHES
                {
                    path: "inventory/dispatches",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/inventory/dispatches/DispatchList.vue"
                        ),
                    name: "inventory 6",
                    meta: { name: "inventory 6" },
                    beforeEnter: checkAuth,
                },
                // INSTALL REPORT
                {
                    path: "inventory/install-report",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/inventory/install_report/InstallReportList.vue"
                        ),
                    name: "inventory 7",
                    meta: { name: "inventory 7" },
                    beforeEnter: checkAuth,
                },

                // -------------------- WASSHA  DASHBOARD-----------------------------

                // WASSHA DASHBOARD
                {
                    path: "wassha-dashboard/transaction-history",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/washa-dashboard/TransactionHistoryList"
                        ),
                    name: "wassha dashboard 1",
                    meta: { name: "wassha dashboard 1" },
                    beforeEnter: checkAuth,
                },
                {
                    path: "wassha-dashboard/manual-pt-approval",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/washa-dashboard/ManualPtApproval"
                        ),
                    name: "wassha dashboard 2",
                    meta: { name: "wassha dashboard 2" },
                    beforeEnter: checkAuth,
                },

                {
                    path: "wassha-dashboard/sent-sms",
                    component: () =>
                        // @ts-ignore
                        import(
                            "./components/administrator/washa-dashboard/SentSMS"
                        ),
                    name: 'wassha dashboard 4',
                    meta: { name: 'wassha dashboard 4' },
                    beforeEnter: checkAuth,
                },
                {
                    path: 'wassha-dashboard/issued-activation-codes',
                    component: () =>
                        // @ts-ignore
                        import('./components/administrator/washa-dashboard/IssuedActivationCodes'),
                    name: 'wassha dashboard 5',
                    meta: { name: 'wassha dashboard 5' },
                    beforeEnter: checkAuth,
                },

            ],
        },

        // -------------------- AUTH URL-----------------------------
      

        {
            path: '/',
            component: () =>
                // @ts-ignore
                import('./components/auth/Login.vue'),
           
            name: 'login',
        },
        {
            path: '/forget-password',
            component: () =>
                // @ts-ignore
                import('./components/auth/ForgetPassword.vue'),
            name: 'forget-password',

        },

        {
            path: '*',
            component: () =>
                // @ts-ignore
                import('./components/helpers/PageNotFound.vue'),
            name: 'page-not-found',
        },

    ],

    // [**END ROUTES DEFINITION***]
};
