import Vue from 'vue';

// Impory axios for requests to the microservices

import axios from 'axios';

Vue.mixin({
  methods: {
    // format phone number by adding country code
    async formatPhoneNumber({ phoneNumber, countryCode }) {
      if (String(phoneNumber).charAt(0) == '0')
      // if the number has zero then we ignore zero and concatinet it with country code
      { return String(countryCode) + String(phoneNumber).substring(1); }
      // the number has already been added with the country code
      if (String(phoneNumber).substring(0, String(countryCode).length) == String(countryCode)) return String(phoneNumber);
      return String(countryCode) + String(phoneNumber);
    },
    // this function formats number in K,M and B
    numberFormatter({ number }) {
      let newnumber = number;

      if (Math.abs(Number(number)) > 999 && Math.abs(Number(number)) <= 999999) {
        newnumber = `${(Math.abs(Number(number)) / 1000).toFixed(2)} K`;
      } else if (Math.abs(Number(number)) > 999999 && Math.abs(Number(number)) <= 999999999) {
        newnumber = `${(Math.abs(Number(number)) / 1000000).toFixed(2)} M`;
      } else if (Math.abs(Number(number)) > 999999999 && Math.abs(Number(number)) <= 999999999999) {
        newnumber = `${(Math.abs(Number(number)) / 1000000).toFixed(2)} B`;
      }


      
      return newnumber;
    },

    // function to format currency
    formatcurrencyNumber({number}) {
      return Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'short'
      }).format(number);
    },
    // this function calculate the percentage for the specific denominator and numerator
    percentCalculator({ denominator, numerator }) {
      if (Number(denominator) === 0) return 0;

      const percent = (((Number(numerator) / Number(denominator)) * 100)).toFixed(1);
      return percent;
    },
    // it receives month in number e.g 7 and returnes String like Jul
    formatMonthToShort(month) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return months[Number(month) - 1];
    },
    // this function helps on generating fiscal year from 2020 and return an array
    generateFiscalYear() {
      const initial_year = 2020;
      const curent_year = new Date().getFullYear();

      const loop_year = curent_year - initial_year + 1;
      const fiscalYearArray = [];
      fiscalYearArray.push({ text: 'Last 6 Months', value: '6 Months' });
      for (let i = 0; i < loop_year; i++) {
        fiscalYearArray.push({ text: `FY${String(initial_year + i).substring(2)}(April ${initial_year + i} - March ${initial_year + i + 1})`, value: (initial_year + i) });
      }

      return fiscalYearArray.sort((a, b) => b.value - a.value);
    },
    // this function returns the list of all months
    monthList() {
      const months = ['January', 'February', 'March', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return months;
    },

    async uploadStatisticsToDB(data) {
      const url = `${window.node_base_url}general-info/add-analysis`;
      const res = axios
        .post(url, data)
        .then((response) => response).catch((err) => err);

      return res;
    },

    groupItemBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
  },
});
