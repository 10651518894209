var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","elevation":"1","clipped-right":"","color":"white"}},[(!_vm.drawer)?_c('v-app-bar-nav-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","bottom":"","max-height":"300","open-on-hover":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-menu-open")])]}}],null,false,21274200)},[_c('v-btn',{staticClass:"menu",attrs:{"tile":"","dark":"","min-width":"100","elevation":"0","color":"secondary"}},[_vm._v("Menu")])],1)],1):_vm._e(),_c('h3',{staticClass:"ml-4 hide-on-mobile",staticStyle:{"color":"#0847a9"}},[_vm._v(" "+_vm._s(_vm.page_title)+" ")]),_c('v-spacer'),_c('p',{staticClass:"text-body-1 my-auto mr-5"},[_vm._v("TZ/ "+_vm._s(_vm.user_role))]),_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","bottom":"","max-height":"300","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"margin-right",attrs:{"color":"#F5F5F5"}},'v-avatar',attrs,false),on),[_c('h3',[_vm._v(_vm._s(String(_vm.username).slice(0, 1)))])])]}}])},[_c('v-card-text',{staticStyle:{"background-color":"white"}},[_vm._v(_vm._s(_vm.username)+" ("+_vm._s(_vm.user_id)+")")]),_c('v-btn',{staticClass:"menu",attrs:{"block":"","tile":"","light":"","elevation":"0"},on:{"click":function($event){return _vm.logOut()}}},[_vm._v(" Log out"),_c('v-spacer')],1)],1)],1),_c('v-navigation-drawer',{attrs:{"app":"","dark":"","color":"primary"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card-text',[_c('v-row',{staticClass:"col-12",attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"col-10"},[_c('v-img',{attrs:{"src":"/logo-dark_.png","contain":""}})],1),_c('div',{staticClass:"col-2"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","bottom":"","max-height":"300","open-on-hover":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":hover ? 'secondary' : null}},'v-avatar',attrs,false),on),[(_vm.drawer)?_c('v-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_vm._v("mdi-menu-open")]):_vm._e()],1)]}}],null,true)},[_c('v-btn',{staticClass:"menu",attrs:{"tile":"","dark":"","min-width":"100","elevation":"0","color":"secondary"}},[_vm._v("Menu")])],1)]}}])})],1)])],1),_c('v-divider'),_c('v-btn',{staticClass:"menu",attrs:{"block":"","tile":"","large":"","color":_vm.menuoption == 'dashboard'
    ? 'secondary' : 'primary',"elevation":"0"},on:{"click":function($event){return _vm.goTo({ title: 'Summary Board', navigateTo: 'dashboard', menuoption: 'dashboard', state: '' })}}},[_c('v-icon',{staticClass:"mr-3",staticStyle:{"margin-left":"-10px"}},[_vm._v("mdi-monitor-dashboard")]),_vm._v("Summary Board"),_c('v-spacer')],1),_c('v-expansion-panels',{attrs:{"hover":true,"flat":"","tile":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('tracking')),expression:"checkPermissions('tracking')"}],key:"tracking",staticStyle:{"background-color":"transparent"}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-badge',{attrs:{"bottom":"","dot":"","bordered":"","color":"green","offset-x":"10","content":""}},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v("mdi-av-timer")])],1),_vm._v("Tracking"),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{key:"0",style:({
          backgroundColor:
            _vm.menuoption == 'subscription-tracking'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('tracking')),expression:"checkPermissions('tracking')"}],staticClass:"menu",attrs:{"elevation":"0","text":"","tile":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Basic Fee Tracker', navigateTo: 'subscription-tracking', menuoption: 'subscription-tracking', state: 0 })}}},[_c('v-badge',{attrs:{"inline":"","color":"warning","content":" Live ","offset-y":"30"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Basic Fee ")],1),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'recharge-tracking'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('tracking')),expression:"checkPermissions('tracking')"}],staticClass:"menu",attrs:{"elevation":"0","text":"","tile":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Recharge Fee Tracker', navigateTo: 'recharge-tracking', menuoption: 'recharge-tracking', state: 0 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Recharge Fee"),_c('v-spacer')],1)],1)],1),_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('customermanagement')),expression:"checkPermissions('customermanagement')"}],staticStyle:{"background-color":"transparent"}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v("mdi-account-group")]),_vm._v(" Customer "),_c('br'),_vm._v(" Management "),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'cus' ? 'rgba(255,255,255,0.3)' : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","elevation":"0","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Customer List', navigateTo: 'customers', menuoption: 'cus', state: 1 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Customer List "),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'customer-analysis' ? 'rgba(255,255,255,0.3)' : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","elevation":"0","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Customer Analysis', navigateTo: 'customer-analysis', menuoption: 'customer-analysis', state: 1 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Customer Analysis "),_c('v-spacer')],1)],1)],1),_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('activationcode')),expression:"checkPermissions('activationcode')"}],staticStyle:{"background-color":"transparent"}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-view-dashboard")]),_vm._v(" Manual Transactions "),_c('v-spacer')],1)],1),((_vm.permissions.create_manual_pt_request))?_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'create-manual-point'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Manual Pt Request Form', navigateTo: 'create-manual-point', menuoption: 'create-manual-point', state: 2 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Manual Pt Request"),_c('v-spacer')],1)],1):_vm._e(),_c('v-expansion-panel-content',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('manualptapproval')),expression:"checkPermissions('manualptapproval')"}],style:({
          backgroundColor:
            _vm.menuoption == 'manual-pt-approval'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Manual Pt Approval', navigateTo: 'manual-pt-approval', menuoption: 'manual-pt-approval', state: 2 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Manual Pt Approval"),_c('v-spacer')],1)],1)],1),_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('transactions')),expression:"checkPermissions('transactions')"}],staticStyle:{"background-color":"transparent"}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"dark":""}},[_vm._v("mdi-cash")]),_vm._v(" Transactions "),_c('br'),_c('v-spacer')],1)],1),(_vm.permissions.view_summary)?_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'transaction-summary'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","elevation":"0","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Summary', navigateTo: 'transaction-summary', menuoption: 'transaction-summary', state: 3 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Summary "),_c('v-spacer')],1)],1):_vm._e(),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'history-live' ? 'rgba(255,255,255,0.3)' : null,
        })},[_c('v-btn',{staticClass:"menu mt-1 float-left",attrs:{"text":"","elevation":"0","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Raw Transactions', navigateTo: 'transaction-history-live', menuoption: 'history-live', state: 3 })}}},[_c('v-badge',{attrs:{"inline":"","color":"warning","content":" General ","offset-y":"0"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Raw")],1),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'history-backup' ? 'rgba(255,255,255,0.3)' : null,
        })},[_c('v-btn',{staticClass:"menu mt-1 float-left",attrs:{"text":"","elevation":"0","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Wallet Transactions', navigateTo: 'transaction-history-backup', menuoption: 'history-backup', state: 3 })}}},[_c('v-badge',{attrs:{"inline":"","color":"green","content":" Specific ","offset-y":"0"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Wallet")],1),_c('v-spacer')],1)],1),(_vm.permissions.view_instalment_payment)?_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'instalment-history'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","elevation":"0","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Instalment', navigateTo: 'instalment-history', menuoption: 'instalment-history', state: 3 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Instalment"),_c('v-spacer')],1)],1):_vm._e()],1),_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('inventory')),expression:"checkPermissions('inventory')"}],staticStyle:{"background-color":"transparent"}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-cog")]),_vm._v(" Inventory "),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'inventory-summary'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Summary', navigateTo: 'inventory/summary', menuoption: 'inventory-summary', state: 4 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Summary "),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'inventory-bulk-asset'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'All Bulk Assets', navigateTo: 'inventory/bulk-asset', menuoption: 'inventory-bulk-asset', state: 4 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("All Bulk Assets"),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'inventory-ac-asset-list'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'All Assets', navigateTo: 'inventory/ac-asset', menuoption: 'inventory-ac-asset-list', state: 4 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("All Assets"),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'inventory-dispatches'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'All Dispatches', navigateTo: 'inventory/dispatches', menuoption: 'inventory-dispatches', state: 4 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("All Dispatches"),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'inventory-all-arrivals'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'All Arrivals', navigateTo: 'inventory/all-arrivals', menuoption: 'inventory-all-arrivals', state: 4 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("All Arrivals"),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'inventory-install-report'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Install Report', navigateTo: 'inventory/install-report', menuoption: 'inventory-install-report', state: 4 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Install Report"),_c('v-spacer')],1)],1)],1),_c('v-expansion-panel',{staticStyle:{"background-color":"transparent"}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-air-conditioner")]),_vm._v(" AC Assets "),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'ded' ? 'rgba(255,255,255,0.3)' : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'AC Asset List', navigateTo: 'ac-asset', menuoption: 'ac-asset', state: 8 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("AC Assets List"),_c('v-spacer')],1)],1)],1),_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('adminsettings')),expression:"checkPermissions('adminsettings')"}],staticStyle:{"background-color":"transparent"}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-cog")]),_vm._v(" Admin User Setting "),_c('v-spacer')],1)],1),(_vm.permissions.view_user_list)?_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'user-list' ? 'rgba(255,255,255,0.3)' : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'User List', navigateTo: 'user-list', menuoption: 'user-list', state: 6 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("User List "),_c('v-spacer')],1)],1):_vm._e(),(_vm.permissions.view_permission_setting)?_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'permission-settings'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Permission settings', navigateTo: 'permission-settings', menuoption: 'permission-settings', state: 6 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Permission settings "),_c('v-spacer')],1)],1):_vm._e(),(_vm.permissions.view_group_permission)?_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'permission-groups'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Permission goups', navigateTo: 'permission-groups', menuoption: 'permission-groups', state: 6 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Groups of Permissions "),_c('v-spacer')],1)],1):_vm._e(),(_vm.permissions.view_manual_pt_reason)?_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'reason-manual-point'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu mt-1",attrs:{"text":"","tile":"","dark":"","block":""},on:{"click":function($event){return _vm.goTo({ title: 'Manual Pt Request Form', navigateTo: 'reason-manual-point', menuoption: 'reason-manual-point', state: 6 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Manual Pt Request"),_c('v-spacer')],1)],1):_vm._e()],1),_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPermissions('transactions')),expression:"checkPermissions('transactions')"}],staticStyle:{"background-color":"transparent"}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-security")]),_vm._v(" Security Deposit "),_c('v-spacer')],1)],1),_c('v-expansion-panel-content',{style:({
          backgroundColor:
            _vm.menuoption == 'security-customer-list'
              ? 'rgba(255,255,255,0.3)'
              : null,
        })},[_c('v-btn',{staticClass:"menu",attrs:{"block":"","tile":"","large":"","color":_vm.menuoption == 'dashboard'
    ? 'secondary' : 'primary',"elevation":"0"},on:{"click":function($event){return _vm.goTo({ title: 'Security Deposit', navigateTo: 'security-deposit', menuoption: 'securitydeposity', state: 7 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Customer List"),_c('v-spacer')],1),_c('v-btn',{staticClass:"menu",attrs:{"block":"","tile":"","large":"","color":_vm.menuoption == 'dashboard'
    ? 'secondary' : 'primary',"elevation":"0"},on:{"click":function($event){return _vm.goTo({ title: 'Deduction History', navigateTo: 'deduction-history', menuoption: 'deductionhistory', state: 7 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Deduction History"),_c('v-spacer')],1),_c('v-btn',{staticClass:"menu",attrs:{"block":"","tile":"","large":"","color":_vm.menuoption == 'dashboard'
    ? 'secondary' : 'primary',"elevation":"0"},on:{"click":function($event){return _vm.goTo({ title: 'Penalties', navigateTo: 'penalties', menuoption: 'penalties', state: 7 })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v("Penalties"),_c('v-spacer')],1)],1)],1)],1)],1),_c('v-main',[_c('router-view',{attrs:{"pending_approval":_vm.notification.length},on:{"title":_vm.getPageTitle}}),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","width":"500","transition":"dialog-top-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"text-center"},[_c('v-card-title',{staticClass:"text-h5 secondary"},[_vm._v(" Inactivity Warning ")]),_c('v-card-text',{staticClass:"text-body-1"},[_c('p',[_vm._v("Baridi Baridi Dashboard has detected no activity."),_c('br'),_vm._v(" You will be logged out in"),_c('br')]),_c('v-progress-circular',{attrs:{"rotate":-90,"size":100,"width":10,"value":_vm.value,"color":"primary"}},[_vm._v(_vm._s(_vm.countDown)+" Sec")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.resetTimerFromDialog()}}},[_vm._v("Cancel logout")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }