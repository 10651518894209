<template>
  <v-row class="full-screen" align="center" justify="center">
    <v-img src="/loader.gif" contain height="150" width="150"></v-img>
  </v-row>
</template>

<script>
export default {

};
</script>

<style scoped>

.full-screen{
    min-height: 80vh;
}

</style>
