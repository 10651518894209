import Vue from 'vue';
// @ts-ignore
import App from './App.vue';

// for csv data format downloads
// @ts-ignore
import JsonCSV from 'vue-json-csv';

// for routes the 'Vue Router has been used
// this line import Vue Router
import VueRouter from 'vue-router';

// material design used is vuetify

// this line import vuetify as a material desin for this project
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

// import VueChatkick for graphs
import VueChartkick from 'vue-chartkick';

// importing highcharts
import HighchartsVue from 'highcharts-vue';

// for translation

// axios has been uses for processing requests and responses in the app
import axios from 'axios';

// importing horizontal nav bar for global usage
// @ts-ignore
import HorizontalNavBar from './components/navbars/Horizontal-Nav-Bar.vue';
// import age loader
// @ts-ignore
import PageLoader from './components/helpers/PageLoader.vue';
import routelist from './routes';

// registering gloabal function which can be used anywhere in any component
import '@/components/helpers/mixin';

// registering axios to the scope
// setting axios interception for global auth
// for every request this will be passed as authentication header
axios.defaults.headers = { Authorization: `Basic ${ window.localStorage.getItem( 'token' ) }`, 'X-ServiceID': 'baridi-api' };
// axios.defaults.headers = { 'Authorization': "Basic " + process.env.VUE_APP_ACCESS_TOKEN }

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
Vue.config.silent = true;
// registering material design Vuetify to the scope
Vue.use( Vuetify );

// registering vue router
Vue.use( VueRouter );

// use chart
Vue.use( VueChartkick );

// use Highchart
Vue.use( HighchartsVue );

// [**GLOBAL COMPONENTS**]//
// REMOVING OR MODIFYING THIS SECTION MAY AFFECT MOST OF THE PAGES
// registering horizontal nav bar
Vue.component( 'horizontal-nav-bar', HorizontalNavBar );
// registering page loader
Vue.component( 'page-loader', PageLoader );

// declaration of urls to used accross the system
const baseUrl = window.location.host;
window.site_url = `http://${ baseUrl }`;
window.img_url = `http://${ baseUrl }`;

// registering csv download package
Vue.component( 'downloadCsv', JsonCSV );

// [**END GLOBAL COMPONENTS**]//

// declaration of urls to used accross the system
const base_url = window.location.host;

// //loading file from .env
// @ts-ignore
window.api_url = `
  ${ process.env.VUE_APP_CORE_API_BASE_URL }/crm/api/v2/`.replace(/\s*/g, '');
// @ts-ignore
window.upload_to_aws_url = process.env.VUE_APP_UPLOAD_TO_AWS_URL_CONNECTION;
// @ts-ignore
window.site_url = `https://${ base_url }`;

// **** API calls to the cron jobs ****/
// @ts-ignore
window.node_base_url = `
  ${ process.env.VUE_APP_CRON_API_BASE_URL }/payment-cron/`.replace(/\s*/g, '');

// **** API calls to the Wallet Transaction ****/
window.node2_base_url = `
${ process.env.VUE_APP_ACTIVATION_CODES_API_BASE_URL }/api/`.trim();

// global fields rules
// used for input validation
// @ts-ignore
window.valueRules = [ ( v ) => !!v || 'This field is required', ( v ) => !!String( v ).trim() || 'Do not use white spaces as value' ];
// @ts-ignore
window.deductionRules = [
  ( v ) => !!v || 'This field is required',
  ( v ) => v > 0 || 'Value must be greater than zero',
  ( v ) => !!String( v ).trim() || 'Do not use white spaces as value',
];

// @ts-ignore
window.emailRules = [
  ( v ) => /.+@.+\..+/.test( v ) || 'Invalid email',
  ( v ) => !!v || 'This field is required',
  ( v ) => !!String( v ).trim() || 'Do not use white spaces as value',
];

// @ts-ignore
window.registrationCodeRule = [
  // (v) => !!v || "This field is required",
  ( v ) => !!v && v.length >= 9 || 'Must be greater or equal to 9 digits',
  ( v ) => !!String( v ).trim() || 'Do not use white spaces as value',
];

// @ts-ignore
window.max_phone_number_count_limit = 9;
// @ts-ignore
window.min_phone_number_count_limit = 9;

// @ts-ignore
window.phoneNumberRule = [
  // @ts-ignore
  // (v) => v != null && (String(v).substring(0,3) != '255') || "Phone number should not start with 255 and not exceed 9",
  ( v ) => v != null && ( String( v ).substring( 0, 3 ) != '255' ) && ( ( v.length == window.max_phone_number_count_limit && String( v ).substring( 0, 1 ) == '0' ) || ( v.length == window.min_phone_number_count_limit && String( v ).substring( 0, 1 ) != '0' ) ) || `Needed ${ window.max_phone_number_count_limit } digits and do not start with 0 or 255.`,
  ( v ) => !!v || 'Value required',
  ( v ) => !!String( v ).trim() || 'Do not use white spaces as value',
];

// Vue router has been used for navigating between pages
// this section defines all routes to which navigation the component is loaded
// [**START ROUTES DEFINITION***]
// modifying or deleting one of the routes may affect system navigation

const routes = routelist.routeslist;
// [**END ROUTES DEFINITION***]

// adding defined routes to the Vue Router
const router = new VueRouter( {
  routes,
  mode: 'history',
} );

new Vue( {
  el: '#app',

  // registering routes to vue
  router,

  // the vuetify has an option for customizing theme.
  // In this section the primary, secondary, accent and error colors have been customized
  // removing this customize will affect on theme colors
  vuetify: new Vuetify(
    //* **[START THEME CUSTOMIZE]***//
    {
      theme: {
        themes: {
          light: {
            primary: '#00205B',
            secondary: '#005eb8',
            accent: '#b1deee',
            error: '#db0025',
            success: '#0fae6f',
            active: '#ffde00',
          },
          dark: {
            primary: '#00205B',
            secondary: '#005eb8',
            accent: '#b1deee',
            error: '#db0025',
            success: '#0fae6f',
            active: '#ffde00',
          },
        },
      },
    },
    //* **[END THEME CUSTOMIZE]***//
  ),
  render: ( h ) => h( App ),
} ).$mount( '#app' );
