<template>
  <v-app>
    <v-app-bar app elevation="1" clipped-right color="white">
      <v-app-bar-nav-icon v-if="!drawer" color="primary" @click="drawer = !drawer">
        <!-- <v-img  height="30px" contain width="30px" src="/favicon.ico"></v-img> -->
        <v-menu transition="scale-transition" offset-y bottom max-height="300" open-on-hover tile>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-menu-open</v-icon>
          </template>
          <v-btn tile class="menu" dark min-width="100" elevation="0" color="secondary">Menu</v-btn>
        </v-menu>
      </v-app-bar-nav-icon>

      <h3 class="ml-4 hide-on-mobile" style="color: #0847a9">
        {{ page_title }}
      </h3>

      <v-spacer></v-spacer>
      <!-- <v-menu v-if="show_notification" v-show="checkPermissions('approval action (manual pt)')" transition="scale-transition" offset-y bottom max-height="300">
        <template v-slot:activator="{ on, attrs }">
        <v-badge offset-x="10" color="red" :value="notification.length" :content="notification.length > 10?'9+':notification.length"  offset-y="8" class="mr-10" > <v-icon v-on="on" v-bind="attrs" >
            mdi-bell
          </v-icon>
        </v-badge> 
         
        </template>
        <v-list>
        <v-list-item
          v-for="(item, index) in notification"
          :key="index"
          link
          @click="goTo({ title: 'Manual Pt Approval', navigateTo: 'manual-pt-approval/'+item.id+'/one', menuoption: 'manual-pt-approval', state: 7 })"
        >
          <v-list-item-title>Manual Approval by {{ item.created_by }}</v-list-item-title>
        </v-list-item>
      </v-list>
      </v-menu> -->
      <p class="text-body-1 my-auto mr-5">TZ/ {{ user_role }}</p>
      <v-menu transition="scale-transition" offset-y bottom max-height="300" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-on="on" v-bind="attrs" color="#F5F5F5" class="margin-right">
            <!-- <v-img src="../profile.jpg"></v-img> -->
            <h3>{{ String(username).slice(0, 1) }}</h3>
          </v-avatar>
          <!-- <label class="mr-3 hide-on-mobile">TZ/User-Permission</label> -->
        </template>
        <v-card-text style="background-color: white">{{
            username
        }} ({{ user_id }})</v-card-text>
        <v-btn block tile light elevation="0" @click="logOut()" class="menu">
          Log out<v-spacer></v-spacer>
        </v-btn>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" dark color="primary">
      <v-card-text>
        <v-row class="col-12" justify="center" align="center">
          <div class="col-10">
            <v-img src="/logo-dark_.png" contain> </v-img>
          </div>
          <div class="col-2">
            <v-hover v-slot="{ hover }">
              <v-menu transition="scale-transition" offset-y bottom max-height="300" open-on-hover tile>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar v-bind="attrs" v-on="on" :color="hover ? 'secondary' : null">
                    <v-icon v-if="drawer" @click="drawer = !drawer">mdi-menu-open</v-icon>
                  </v-avatar>
                </template>
                <v-btn tile class="menu" dark min-width="100" elevation="0" color="secondary">Menu</v-btn>
              </v-menu>
            </v-hover>
          </div>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-btn block class="menu" tile large :color="menuoption == 'dashboard'
      ? 'secondary' : 'primary'" elevation="0"
        @click="goTo({ title: 'Summary Board', navigateTo: 'dashboard', menuoption: 'dashboard', state: '' })">
        <v-icon style="margin-left:-10px" class="mr-3">mdi-monitor-dashboard</v-icon>Summary Board<v-spacer></v-spacer>
      </v-btn>
      <v-expansion-panels :hover="true" flat tile v-model="panel" multiple>
        <v-expansion-panel style="background-color: transparent" v-show="checkPermissions('tracking')"
          key="tracking">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-badge bottom dot bordered color="green" offset-x="10" content="">
                <v-icon dark class="mr-3">mdi-av-timer</v-icon>
              </v-badge>Tracking<v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content key="0" :style="{
            backgroundColor:
              menuoption == 'subscription-tracking'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">

            <v-btn class="menu"
              @click="goTo({ title: 'Basic Fee Tracker', navigateTo: 'subscription-tracking', menuoption: 'subscription-tracking', state: 0 })"
              elevation="0" text tile block v-show="checkPermissions('tracking')">
              <v-badge inline color="warning" content=" Live " offset-y="30">
                <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Basic Fee
              </v-badge>
              <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'recharge-tracking'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu"
              @click="goTo({ title: 'Recharge Fee Tracker', navigateTo: 'recharge-tracking', menuoption: 'recharge-tracking', state: 0 })"
              elevation="0" text tile block v-show="checkPermissions('tracking')">
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Recharge Fee<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color: transparent" v-show="checkPermissions('customermanagement')">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon dark class="mr-3">mdi-account-group</v-icon> Customer
              <br />
              Management <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'cus' ? 'rgba(255,255,255,0.3)' : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Customer List', navigateTo: 'customers', menuoption: 'cus', state: 1 })" text
              elevation="0" tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Customer List <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'customer-analysis' ? 'rgba(255,255,255,0.3)' : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Customer Analysis', navigateTo: 'customer-analysis', menuoption: 'customer-analysis', state: 1 })"
              text elevation="0" tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Customer Analysis <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="background-color: transparent" v-show="checkPermissions('activationcode')">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon class="mr-3">mdi-view-dashboard</v-icon> Manual Transactions
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>
          <!-- <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'activation-codes'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }" v-if="permissions.view_activation_code">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Manual Transactions list', navigateTo: 'activation-codes', menuoption: 'activation-codes', state: 2 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Manual Transaction list<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content> -->
          <!-- <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'sent-sms'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }" v-if="(permissions.view_sms == true)">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Sent SMS', navigateTo: 'sent-sms', menuoption: 'sent-sms', state: 2 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Sent SMS<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content> -->
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'create-manual-point'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }" v-if="(permissions.create_manual_pt_request)">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Manual Pt Request Form', navigateTo: 'create-manual-point', menuoption: 'create-manual-point', state: 2 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Manual Pt Request<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content> 
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'manual-pt-approval'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }" v-show="checkPermissions('manualptapproval')">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Manual Pt Approval', navigateTo: 'manual-pt-approval', menuoption: 'manual-pt-approval', state: 2 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Manual Pt Approval<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="background-color: transparent" v-show="checkPermissions('transactions')">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon dark class="mr-3">mdi-cash</v-icon> Transactions
              <br />
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'transaction-summary'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }" v-if="permissions.view_summary">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Summary', navigateTo: 'transaction-summary', menuoption: 'transaction-summary', state: 3 })"
              text elevation="0" tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Summary <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'history-live' ? 'rgba(255,255,255,0.3)' : null,
          }">
            <v-btn class="menu mt-1 float-left"
            @click="goTo({ title: 'Raw Transactions', navigateTo: 'transaction-history-live', menuoption: 'history-live', state: 3 })" text elevation="0"
              tile dark block>
              <v-badge inline  color="warning" content=" General " offset-y="0">
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Raw</v-badge><v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'history-backup' ? 'rgba(255,255,255,0.3)' : null,
          }">
            <v-btn class="menu mt-1 float-left"
              @click="goTo({ title: 'Wallet Transactions', navigateTo: 'transaction-history-backup', menuoption: 'history-backup', state: 3 })" text elevation="0"
              tile dark block>
              <v-badge inline color="green" content=" Specific " offset-y="0">
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Wallet</v-badge><v-spacer></v-spacer>
            
            </v-btn>
          </v-expansion-panel-content>
          
          <!-- <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'subscription-history'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Subscription', navigateTo: 'subscription-history', menuoption: 'subscription-history', state: 3 })"
              text elevation="0" tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Subscription<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content> -->

          <!-- Instalment transaction -->
          <v-expansion-panel-content v-if="permissions.view_instalment_payment" :style="{
            backgroundColor:
              menuoption == 'instalment-history'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Instalment', navigateTo: 'instalment-history', menuoption: 'instalment-history', state: 3 })"
              text elevation="0" tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Instalment<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- <v-expansion-panel style="background-color: transparent" v-show="checkPermissions('deductionsettings')">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon class="mr-3">mdi-content-cut</v-icon> Deduction Settings
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'ded' ? 'rgba(255,255,255,0.3)' : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Deduction Schedule', navigateTo: 'deduction-schedules', menuoption: 'ded', state: 4 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Deduction Schedule <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'upload' ? 'rgba(255,255,255,0.3)' : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Upload CSV to AWS', navigateTo: 'upload', menuoption: 'upload', state: 2 })" text
              tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Upload CSV to AWS <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel> -->



        <v-expansion-panel style="background-color: transparent" v-show="checkPermissions('inventory')">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon class="mr-3">mdi-cog</v-icon> Inventory
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'inventory-summary'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Summary', navigateTo: 'inventory/summary', menuoption: 'inventory-summary', state: 4 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Summary <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'inventory-bulk-asset'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'All Bulk Assets', navigateTo: 'inventory/bulk-asset', menuoption: 'inventory-bulk-asset', state: 4 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>All Bulk Assets<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'inventory-ac-asset-list'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'All Assets', navigateTo: 'inventory/ac-asset', menuoption: 'inventory-ac-asset-list', state: 4 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>All Assets<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'inventory-dispatches'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'All Dispatches', navigateTo: 'inventory/dispatches', menuoption: 'inventory-dispatches', state: 4 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>All Dispatches<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'inventory-all-arrivals'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'All Arrivals', navigateTo: 'inventory/all-arrivals', menuoption: 'inventory-all-arrivals', state: 4 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>All Arrivals<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'inventory-install-report'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Install Report', navigateTo: 'inventory/install-report', menuoption: 'inventory-install-report', state: 4 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Install Report<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="background-color: transparent">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon class="mr-3">mdi-air-conditioner</v-icon> AC Assets
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'ded' ? 'rgba(255,255,255,0.3)' : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'AC Asset List', navigateTo: 'ac-asset', menuoption: 'ac-asset', state: 8 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>AC Assets List<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- <v-btn block class="menu" tile large :color="menuoption == 'dashboard'
          ? 'secondary' : 'primary'" elevation="0"
            @click="goTo({ title: 'Filter Dashboard', navigateTo: 'filter-dashboard', menuoption: 'dashboard', state: '' })">
            <v-icon style="margin-left:-10px" class="mr-3">mdi-monitor-dashboard</v-icon>Filter Dashboard<v-spacer></v-spacer>
        </v-btn> -->

        <!-- <v-expansion-panel style="background-color: transparent" v-if="(permissions.view_dashboard_analysis)">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon class="mr-3">mdi-poll</v-icon> Analytics
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'analysis' ? 'rgba(255,255,255,0.3)' : null,
          }">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Analysis', navigateTo: 'analysis-page', menuoption: 'analysis', state: 5 })" text
              tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Dashboard Analysis<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>

        </v-expansion-panel> -->
        <v-expansion-panel style="background-color: transparent" v-show="checkPermissions('adminsettings')">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon class="mr-3">mdi-cog</v-icon> Admin User Setting
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'user-list' ? 'rgba(255,255,255,0.3)' : null,
          }" v-if="permissions.view_user_list">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'User List', navigateTo: 'user-list', menuoption: 'user-list', state: 6 })" text
              tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>User List <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>

          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'permission-settings'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }" v-if="permissions.view_permission_setting">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Permission settings', navigateTo: 'permission-settings', menuoption: 'permission-settings', state: 6 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Permission settings <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'permission-groups'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }" v-if="permissions.view_group_permission">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Permission goups', navigateTo: 'permission-groups', menuoption: 'permission-groups', state: 6 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Groups of Permissions <v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'reason-manual-point'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }" v-if="permissions.view_manual_pt_reason">
            <v-btn class="menu mt-1"
              @click="goTo({ title: 'Manual Pt Request Form', navigateTo: 'reason-manual-point', menuoption: 'reason-manual-point', state: 6 })"
              text tile dark block>
              <v-icon size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Manual Pt Request<v-spacer></v-spacer>
            </v-btn>
          </v-expansion-panel-content> 
        </v-expansion-panel>
        <!-- Security deposit region -->
        <v-expansion-panel style="background-color: transparent" v-show="checkPermissions('transactions')">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-row align="center">
              <v-icon class="mr-3">mdi-security</v-icon> Security Deposit
              <v-spacer></v-spacer>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content :style="{
            backgroundColor:
              menuoption == 'security-customer-list'
                ? 'rgba(255,255,255,0.3)'
                : null,
          }">
           
      <v-btn block class="menu" tile large :color="menuoption == 'dashboard'
      ? 'secondary' : 'primary'" elevation="0"
        @click="goTo({ title: 'Security Deposit', navigateTo: 'security-deposit', menuoption: 'securitydeposity', state: 7 })">
        <v-icon  size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Customer List<v-spacer></v-spacer>
      </v-btn>

      <v-btn block class="menu" tile large :color="menuoption == 'dashboard'
      ? 'secondary' : 'primary'" elevation="0"
        @click="goTo({ title: 'Deduction History', navigateTo: 'deduction-history', menuoption: 'deductionhistory', state: 7 })">
        <v-icon  size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Deduction History<v-spacer></v-spacer>
      </v-btn> 
      <v-btn block class="menu" tile large :color="menuoption == 'dashboard'
      ? 'secondary' : 'primary'" elevation="0"
        @click="goTo({ title: 'Penalties', navigateTo: 'penalties', menuoption: 'penalties', state: 7 })">
        <v-icon  size="15" class="mr-1">mdi-checkbox-blank-circle</v-icon>Penalties<v-spacer></v-spacer>
      </v-btn> 
          </v-expansion-panel-content> 

          </v-expansion-panel>

      </v-expansion-panels>
     
    </v-navigation-drawer>
    <v-main>
      <router-view @title="getPageTitle" :pending_approval="notification.length">

      </router-view>

      <!-- PopUp dialog -->
      <div class="text-center">
        <v-dialog v-model="dialog" persistent width="500" transition="dialog-top-transition" >

          <v-card class="text-center">
            <v-card-title class="text-h5 secondary">
              Inactivity Warning
            </v-card-title>

            <v-card-text class="text-body-1">
              <p>Baridi Baridi Dashboard has detected no activity.<br> You will be logged out in<br> </p> 
              
              <v-progress-circular :rotate="-90"
                  :size="100"
                  :width="10"
                  :value="value"
                  color="primary">{{countDown}} Sec</v-progress-circular>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="resetTimerFromDialog()">Cancel logout</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>

<script>
//var timerInterval;
export default {
  data: () => ({
    drawer: null,
    panel: [0],
    page_title: null,
    user_role: null,
    user_id: null,
    //for a login username
    username: null,
    show_notification:true,
    notification:[
     
    ],
    warningTimer:null,    // TimerValue to display warning dialog
    logoutTimer:null,     // TimerValue to logout user
    events:['keypress','load','click'],    // User events to be monitored
    dialog:false,
    interval:{},
    value:100,
    // seconds:15,
    countDown: 30,

    permissions:{
      view_sms: false,
      create_manual_pt_request: false,
      view_dashboard_analysis: false,
      view_activation_code: false,
      view_summary_board: false,
      view_summary: false,
      admin_setting_page: false,
      view_group_permission: false,
      view_permission_setting: false,
      view_setup_reason: false,
      view_user_list: false,
      view_manual_pt_reason: false,
      view_instalment_payment: false,
    }
  }),
  props: {
    //this prop help on the active classess for exapansion panels. For every navigation state changes
    state: {
      type: String,
      default: null,
    },

    //options for selected menu. This prop change color for active menu option
    //the options are
    //*********************//
    //ded - for deduction list option
    //new-cus for add new customer option
    //upload for upload CSV to AWS option
    //********************//
    menuoption: {
      type: String,
      default: null,
    }
  },

  mounted() {
    // Will monitor user activity and startTimer()
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer)
    }, this);
    clearTimeout(this.countDown)

    this.startTimer();
  },

  methods: {
    loadNotificationsForManual(){
      let url = `${window.node_base_url}general-info/get-pending-manual`;
      this.$axios
        .get(url)
        .then((response) => {
          this.notification = response.data;
        })
        .catch(() => {})
    },
    getPageTitle(value) {
      this.page_title = value;
      if(value == 'Manual Pt Approval'){
        this.show_notification = false;
      }else{
        this.show_notification = true;
      }
    },
    showNotify(value){
      this.show_notification = value;
    },
    goTo(page) {
      this.panel = [page.state];
      this.menuoption = page.menuoption;
      this.$router.push(`/homepage/${page.navigateTo}`).catch(() => { });
    },
    logOut() {
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("username", "");
      window.localStorage.setItem("role", "");
      // window.localStorage.setItem("role_permission", "");
      window.localStorage.setItem("New_permissions", "");
      window.localStorage.clear();
      window.location.href = "/";
    },

    // Function to fetch user permissions
    async rolePermissionFilter() {
      // New permission setting
      let new_modules = JSON.parse(window.localStorage.getItem("New_permissions"));

      // view summary board permission
      let summary_board_pageButton = await new_modules.map((mod) => 
          mod.permission.toLowerCase() == "summaryboard:view:pagebutton");

          if(summary_board_pageButton.includes(true)){
            this.permissions.view_summary_board = true;
          }

      // View sent sms permission
      let viewSMS = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "activationcode:sentsms:view");
      
        if (viewSMS.includes(true)) {
          this.permissions.view_sms = true;
        }
      
      // Create manual pt request permission
      let create_manual_pt = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "activationcode:manualptrequest:create");

        if (create_manual_pt.includes(true)) {
          this.permissions.create_manual_pt_request = true;
        }
      
      // View Wallet Transaction permission
      let viewActivationCode = new_modules.map((mod) => 
        mod.permission.toLowerCase() == "activationcode:activationcodelist:view");

        if (viewActivationCode.includes(true)) {
          this.permissions.view_activation_code = true;
        }
      
      // view dashboard analytics permission
      let dashboard_analytics = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "analysis:dashboardanalysis:view");

        if(dashboard_analytics.includes(true)){
          this.permissions.view_dashboard_analysis = true;
        }

      // view transaction summary
      let summary = await new_modules.map((mod) =>
        mod.permission.toLowerCase() == "transactions:summary:view");

        if (summary.includes(true)) {
          this.permissions.view_summary = true;
        }

      // view admin setting page permission
      let admin_setting = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "adminsettings:userlist:view");

        if (admin_setting.includes(true)) {
          this.permissions.admin_setting_page = true;
        }
      // view group permission
      let groupPermissions = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "adminsettings:groupsofpermissions:view");

        if (groupPermissions.includes(true)) {
          this.permissions.view_group_permission = true;
        }
      // view permission list setting
      let permissionSetting = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "adminsettings:permissionsettings:view");

        if (permissionSetting.includes(true)) {
          this.permissions.view_permission_setting = true;
        }
      // create new permission
      let viewSetupReason = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "");

        if (viewSetupReason.includes(true)) {
          this.permissions.view_setup_reason = true;
        }
      // view user list permission
      let viewUserList = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "adminsettings:userlist:view");

        if (viewUserList.includes(true)) {
          this.permissions.view_user_list = true;
        }
      // view reason of manual pt request permission
      let viewManualPtReason = await new_modules.map((mod) => 
        mod.permission.toLowerCase() == "adminsettings:setupmanualptrequest:view");

        if (viewManualPtReason.includes(true)) {
          this.permissions.view_manual_pt_reason = true;
        }

      // view instalment transaction
      let viewPayment = await new_modules.map((mod) => 
                mod.permission.toLowerCase() == 'instalment:payment:view');
                if(viewPayment.includes(true)){
                    this.permissions.view_instalment_payment = true;
                }
    },

    checkPermissions(link_module) {
      //console.log(link_module)
      let modules = JSON.parse(window.localStorage.getItem("New_permissions"));
      // let result = modules.map((mod) => link_module.includes(mod.permission.toLowerCase()));
      let result = modules.map((mod) => mod.permission.toLowerCase().includes(link_module));
      // console.log(result)
      return result.includes(true);
    },

    /* ----------------------------------
      startTimer,resetTimer,resetTimerFromDialog,warningDialogMessage,
      logoutWhenIdle funtions run to monitor user activities
    */
    startTimer(){
      this.warningTimer = setTimeout(this.warningDialogMessage, 900000)
      this.logoutTimer = setTimeout(this.logoutWhenIdle, 930000)

    },
    resetTimer(){
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
      // this.dialog = false

      this.startTimer()
    },

    resetTimerFromDialog(){
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
      this.dialog = false

      this.startTimer()
    },

    warningDialogMessage(){
      this.dialog = true
      this.countDownTimer()
    },
    countDownTimer () {
      if (this.countDown >= 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.value -= 3.4
          this.countDownTimer()
        }, 1000)
      }
      else{
        this.countDown = 30
        this.value = 100
      }
    },
    logoutWhenIdle(){
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("username", "");
      window.localStorage.setItem("role", "");
      window.localStorage.setItem("New_permissions", "");
      window.localStorage.clear();
      window.location.href = "/";
    }
  },
  destroyed(){
//clearInterval(timerInterval);
clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
  },
  created() {
    //setting an active nav pannel
    //tracking
    this.username = window.localStorage.getItem("username");
    this.user_role = window.localStorage.getItem("role")
    this.user_id = window.localStorage.getItem("user_id");

    this.panel = [this.state];

    this.rolePermissionFilter();
    //timerInterval =  setInterval(()=>this.loadNotificationsForManual(),5000)
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.avatar-margin {
  margin-right: 50px;
}

@media only screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }

  .avatar-margin {
    margin-right: 0px;
  }
}
</style>