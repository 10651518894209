<template>
    <div id="app">
        <router-view> </router-view>
    </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
#app {
    /* font-family: 'Varela Round', sans-serif; */
    font-family: "Mulish", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
